/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice, getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue, translateStatic } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";
import bed from "../images/bed.svg";
import bathtub from "../images/bathtub.svg";
import totalArea from "../images/total-area.svg";
import builtArea from "../images/built-area.svg";
import tagicon from "../images/tag.png";

import "../../theme5/scss/home-carousel.scss";
import * as theme5Styles from "../scss/theme5.module.scss";

const PropertyList = ({ properties, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          company: { currency: company_currency },
          setting: {
            display_watermark
          }
        },
      },
    },
  } = siteData;

  const renderProperties = () => {
    return properties.map((property) => {
      const {
        pictures,
        category,
        property_type,
        measurement_unit,
        translations,
      } = property;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div key={property.uuid} className={theme5Styles.colm}>
          <div className={`${theme5Styles.productBox}`}>
            <Link
              to={`/${locale}/property/${property.uuid}`}
              className={theme5Styles.image}
            >
              <img alt="property" src={picture} />
              <span className={`${theme5Styles.price}`}>
                <span className={`${theme5Styles.tag}`}>
                  <img src={tagicon} allt="" />
                </span>
                {formatPrice(property, company_currency)}
              </span>
            </Link>
            <div className={theme5Styles.details}>
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslatedHeadline(
                      property,
                      locale,
                      defaultLocale
                    ) || "NA",
                }}
              ></h4>
              <div className={`${theme5Styles.category}`}>
                <span className={`${theme5Styles.catg}`}>
                  {(() => {
                    const propertyType = property_type
                      ? getTranslatedValue(
                          property_type.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;
                    const propertyCategory = category
                      ? getTranslatedValue(
                          category.translations,
                          "name",
                          locale,
                          defaultLocale
                        )
                      : null;

                    if (propertyType && !propertyCategory) {
                      return propertyType;
                    }
                    if (!propertyType && propertyCategory) {
                      return propertyCategory;
                    }
                    if (propertyType && propertyCategory) {
                      return `${propertyType} for ${propertyCategory}`;
                    }
                  })()}
                </span>
                <span className={`${theme5Styles.uuid}`}>
                  {property.reference}
                </span>
              </div>
              <p>{property.location}</p>
              <div className={theme5Styles.features}>
                <div className={theme5Styles.li}>
                  <img alt="bed" src={bed} />
                  <span>{property.bedrooms} {translateStatic("bedrooms", locale, defaultLocale)}</span>
                </div>
                <div className={theme5Styles.li}>
                  <img alt="bed" src={bathtub} />
                  <span>{property.bathrooms} {translateStatic("bathrooms", locale, defaultLocale)}</span>
                </div>
                {property.land_area_value && measurement_unit && <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                  <img alt="image" src={totalArea} />
                  <span>
                    {property.land_area_value + ' ' + (measurement_unit.name || "")}&nbsp;
                    {translateStatic("total", locale, defaultLocale)}
                  </span>
                </div>}
                {property.built_in_area_value && measurement_unit && <div className={`${theme5Styles.li} ${theme5Styles.sm}`}>
                  <img alt="image" src={builtArea} />
                  <span>
                    {property.built_in_area_value + ' ' + (measurement_unit.name || "")}&nbsp;
                    {translateStatic("built", locale, defaultLocale)}
                  </span>
                </div>}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme5Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && properties.length > 3) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme5-product-slider',

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 639,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme5Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderProperties()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme5Styles.productRow}`}>{renderProperties()}</div>
        );
      })()}
    </div>
  );
};

export default PropertyList;
