/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice, getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue, translateStatic } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";

import "../../theme4/scss/home-carousel.scss";
import * as theme4Styles from "../scss/theme4.module.scss";

// eslint-disable-next-line react/prop-types
const PropertyList = ({ properties, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          company: { currency: company_currency },
          setting: {
            display_watermark
          }
        },
      },
    },
  } = siteData;

  const renderProperties = () => {
    return properties.map((property) => {
      const {
        pictures,
        property_type,
        category,
        measurement_unit,
        translations,
      } = property;

      let picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme4Styles.colm} key={property.uuid}>
          <div className={`${theme4Styles.productBox}`}>
            <Link
              to={`/${locale}/property/${property.uuid}`}
              className={theme4Styles.image}
            >
              <img src={picture} alt="" />
              <span className={theme4Styles.uuid}>{property.reference}</span>
              <span className={theme4Styles.category}>
                {(() => {
                  const propertyType = property_type
                    ? getTranslatedValue(
                        property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                      )
                    : null;
                  const propertyCategory = category
                    ? getTranslatedValue(
                        category.translations,
                        "name",
                        locale,
                        defaultLocale
                      )
                    : null;

                  if (propertyType && !propertyCategory) {
                    return propertyType;
                  }
                  if (!propertyType && propertyCategory) {
                    return propertyCategory;
                  }
                  if (propertyType && propertyCategory) {
                    return `${propertyType} for ${propertyCategory}`;
                  }
                })()}
              </span>
              <div className={`${theme4Styles.infoC}`}>
                <div className={`${theme4Styles.leftC}`}>
                  <span className={theme4Styles.price}>
                    {formatPrice(property, company_currency)}
                  </span>
                  <h4
                    dangerouslySetInnerHTML={{
                      __html:
                        getTranslatedHeadline(
                          property,
                          locale,
                          defaultLocale
                        ) || "",
                    }}
                  ></h4>
                  <h4>{property.location}</h4>
                </div>
                <ul className={theme4Styles.features}>
                  <li>
                    {property.bedrooms}
                    <abbr>Beds</abbr>
                  </li>
                  <li>
                    {property.bathrooms}
                    <abbr>Baths</abbr>
                  </li>
                  {(property.built_in_area_value) && measurement_unit && <li>
                    {property.built_in_area_value}
                    {" " + measurement_unit ? measurement_unit.name : ""}
                    <abbr>{translateStatic("built", locale, defaultLocale)}</abbr>
                  </li>}
                  {(property.land_area_value) && measurement_unit && <li>
                    {property.land_area_value}
                    {" " + measurement_unit ? measurement_unit.name : ""}
                    <abbr>{translateStatic("total", locale, defaultLocale)}</abbr>
                  </li>}
                </ul>
              </div>
            </Link>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme4Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && properties.length > 3) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme4-product-slider',

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 599,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme4Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderProperties()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme4Styles.productRow}`}>{renderProperties()}</div>
        );
      })()}
    </div>
  );
};

export default PropertyList;
