/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice, getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";
import bedIcon from "../../../images/bed.svg";
import bathIcon from "../../../images/bathtub.svg";
import builtAreaIcon from "../../../images/built-area.svg";
import landAreaIcon from "../../../images/plans.png";

import "../scss/home-carousel.scss";
import * as theme1Styles from "../scss/theme1.module.scss";

const PropertyList = ({ properties, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          company: { currency: company_currency },
          setting: {
            display_watermark
          }
        },
      },
    },
  } = siteData;

  const renderProperties = () => {
    return properties.map((property) => {
      const { 
        pictures, 
        property_type, 
        category, 
        translations, 
        measurement_unit 
      } = property;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme1Styles.colm} key={property.uuid}>
          <div className={`${theme1Styles.productBox}`}>
            <Link
              to={`/${locale}/property/${property.uuid}`}
              className={theme1Styles.image}
            >
              <img alt="image" src={picture} />
              {(() => {
                if (property_type && property_type.translations.length > 0) {
                  const propertyType = getTranslatedValue(
                    property_type.translations,
                    "name",
                    locale,
                    defaultLocale
                  );
                  if (propertyType) {
                    return (
                      <span className={theme1Styles.type}>
                        {propertyType}
                      </span>
                    );
                  }
                }
              })()}
            </Link>
            <div className={theme1Styles.details}>
              <div className={theme1Styles.top}>
                <h3>
                  <Link to={`/${locale}/property/${property.uuid}`}>
                    {property.reference}
                  </Link>
                </h3>
                <h4
                  dangerouslySetInnerHTML={{
                    __html:
                      getTranslatedHeadline(
                        property,
                        locale,
                        defaultLocale
                      ) || ""
                  }}
                ></h4>
                <p>{property.location}</p>
                <div className={`${theme1Styles.priceC}`}>
                  {(() => {
                    if (category && category.translations.length > 0) {
                      const propertyCategory = getTranslatedValue(
                        category.translations,
                        "name",
                        locale,
                        defaultLocale
                      );
                      if (propertyCategory) {
                        return (
                          <span className={theme1Styles.category}>
                            <span>{propertyCategory}</span>
                          </span>
                        );
                      }
                    }
                  })()}
                  <span className={theme1Styles.price}>
                    {formatPrice(property, company_currency)}
                  </span>
                </div>
              </div>
              <div className={theme1Styles.bottom}>
                <div className={theme1Styles.features}>
                  <div className={theme1Styles.li}>
                    <img alt="image" src={bedIcon} />
                    <span>{property.bedrooms}</span>
                  </div>
                  <div className={theme1Styles.li}>
                    <img alt="image" src={bathIcon} />
                    <span>{property.bathrooms}</span>
                  </div>
                  {property.land_area_value && measurement_unit && <div className={theme1Styles.li}>
                    <img alt="image" src={landAreaIcon} />
                    <span>
                      {property.land_area_value}{' ' + (measurement_unit.name || '')}
                    </span>
                  </div>}
                  {property.built_in_area_value && measurement_unit && <div className={theme1Styles.li}>
                    <img alt="image" src={builtAreaIcon} />                    
                    <span>
                      {property.built_in_area_value}{' ' + (measurement_unit.name || '')}
                    </span>
                  </div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme1Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && properties.length > 4) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme1-product-slider',

            responsive: [
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 899,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 599,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme1Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderProperties()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme1Styles.productRow}`}>{renderProperties()}</div>
        );
      })()}
    </div>
  );
};

export default PropertyList;
