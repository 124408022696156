/* eslint react/prop-types: 0 */
import React, { useContext } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { formatPrice, getTranslatedHeadline } from "../../../helpers/propertyHelper";
import { getTranslatedValue, translateStatic } from "../../../helpers/translationHelper";
import { prepareImageURL } from "../../../helpers/helper";

import property1 from "../images/property-image-1.jpg";

import "../scss/home-carousel.scss";
import * as theme3Styles from "../scss/theme3.module.scss";

const PropertyList = ({ properties, locale, defaultLocale, is_carousel=false }) => {
  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          company: { currency: company_currency },
          setting: {
            display_watermark
          }
        },
      },
    },
  } = siteData;

  const renderProperties = () => {
    return properties.map((property) => {
      const {
        pictures,
        property_type,
        category,
        measurement_unit,
        translations,
      } = property;

      const picture =
        pictures.length > 0
          ? prepareImageURL(pictures[0], website_id, 480, 320, display_watermark == 2)
          : property1;

      return (
        <div className={theme3Styles.colm} key={property.uuid}>
          <div className={`${theme3Styles.productBox}`}>
            <Link
              to={`/${locale}/property/${property.uuid}`}
              className={theme3Styles.image}
            >
              <img src={picture} alt="" />
              {(() => {
                if (property_type && property_type.translations.length > 0) {
                  return (
                    <span className={theme3Styles.type}>
                      {getTranslatedValue(
                        property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                      )}
                    </span>
                  );
                }
              })()}
              <span className={theme3Styles.uuid}>{property.reference}</span>
            </Link>
            <div className={theme3Styles.details}>
              <div className={`${theme3Styles.priceC}`}>
                <span className={theme3Styles.price}>
                  {formatPrice(property, company_currency)}
                </span>
                <ul className={theme3Styles.features}>
                  <li>
                    {property.bedrooms}
                    <abbr> bds</abbr>
                  </li>
                  <li>
                    {property.bathrooms}
                    <abbr> ba</abbr>
                  </li>
                  {property.land_area_value && measurement_unit && <li>
                    {property.land_area_value}
                    <abbr>
                      {" " + measurement_unit ? measurement_unit.name : ""}
                    </abbr>
                    <abbr>{translateStatic("total", locale, defaultLocale)}</abbr>
                  </li>}
                  {property.built_in_area_value && measurement_unit && <li>
                    {property.built_in_area_value}
                    <abbr>
                      {" " + measurement_unit ? measurement_unit.name : ""}
                    </abbr>
                    <abbr>{translateStatic("built", locale, defaultLocale)}</abbr>
                  </li>}
                </ul>
              </div>
              <h4
                dangerouslySetInnerHTML={{
                  __html:
                    getTranslatedHeadline(
                      property,
                      locale,
                      defaultLocale
                    ) || "",
                }}
              ></h4>
              <h5>{property.location}</h5>
              <span className={theme3Styles.category}>
                {(() => {
                  const propertyType = property_type
                    ? getTranslatedValue(
                        property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                      )
                    : null;
                  const propertyCategory = category
                    ? getTranslatedValue(
                        category.translations,
                        "name",
                        locale,
                        defaultLocale
                      )
                    : null;

                  if (propertyType && !propertyCategory) {
                    return propertyType;
                  }
                  if (!propertyType && propertyCategory) {
                    return propertyCategory;
                  }
                  if (propertyType && propertyCategory) {
                    return `${propertyType} for ${propertyCategory}`;
                  }
                })()}
              </span>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={`${theme3Styles.productRowContainer}`}>
      {(() => {
        if (is_carousel && properties.length > 3) {
          let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            className: 'theme3-product-slider',

            responsive: [
              {
                breakpoint: 1023,
                settings: {
                  arrows: false,
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 599,
                settings: {
                  arrows: false,
                  slidesToShow: 1,
                }
              },
            ]
          };

          return (
            <div className={`${theme3Styles.productSlideContainer}`}>
              <Slider {...settings}>{renderProperties()}</Slider>
            </div>
          );
        }

        return (
          <div className={`${theme3Styles.productRow}`}>{renderProperties()}</div>
        );
      })()}
    </div>
  );
};

export default PropertyList;
